.popup {
    visibility: visible;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    background-color: rgba(black, 0.2);

    &__content {
        @include position-absolute-center;
        width: 90%;
        max-height: 95vh;
        overflow-y: auto;
        background-color: $color-grey-light-1;
        padding: 2rem;
        border-radius: 3px;

        display: grid;
        grid-template-rows: 40rem auto;

        @media only screen and (min-width: $bp-medium) {
            padding: 2rem 4rem;
        }
        @media only screen and (min-width: $bp-large) {
            width: 80%;
            grid-template-rows: auto;
            grid-template-columns: 1fr 1fr;
            column-gap: 5rem;
        }
        @media only screen and (min-width: $bp-largest) {
            width: 70%;
            height: 70%;
            padding: 5rem;
        }

        &--left {
            grid-row: 1;
        }
        &--right {
            @media only screen and (min-width: $bp-medium) {
                display: grid;
                grid-template-rows: repeat(3, min-content);
                grid-template-columns: 1fr 1fr;
                column-gap: 1rem;
            }
            @media only screen and (min-width: $bp-large) {
                grid-template-rows: repeat(4, min-content);
                grid-template-columns: 1fr;
                row-gap: 2rem;
            }
            @media only screen and (min-width: $bp-largest) {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                justify-content: space-evenly;
            }
            .heading-4 {
                font-size: 1.8rem;
                text-align: center;
                padding-top: 1rem;
                padding-bottom: 1rem;
                @media only screen and (min-width: $bp-medium) {
                    text-align: start;
                    padding-bottom: 0;
                }
            }
            .project-info {
                background-color: $color-white;
                padding: 2rem;
                width: 90%;
                margin: 0 auto;
                font-size: 1.2rem;
                border-radius: 3px;
                box-shadow: $container-box-shadow;
                line-height: 2.2rem;
                @media only screen and (min-width: $bp-medium) {
                    grid-row: 2;
                    grid-column: 1;
                    align-self: flex-start;
                    margin: 0;
                }
                @media only screen and (min-width: $bp-large) {
                    width: 80%;
                }
                .heading-6 {
                    font-size: 1.4rem;
                }
                &__category {
                }
                &__tools {
                }
                &__link {
                    a {
                        background-color: $color-tertiary;
                        padding: 0.24rem 0.9rem;
                        border: 2px solid $color-tertiary;
                        border-radius: 2px;
                        color: inherit;
                        text-decoration-color: inherit;
                        font-weight: 600;
                        transition: all 0.3s;
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
            .description {
                font-size: 1.4rem;
                text-align: justify;
                @media only screen and (min-width: $bp-medium) {
                    grid-row: 2;
                    grid-column: 2;
                    padding: 0;
                }
                @media only screen and (min-width: $bp-large) {
                    grid-row: 3;
                    grid-column: 1;
                }
            }
            .button-box {
                width: 10rem;
                margin: 0 auto;
                padding-top: 3rem;
                @media only screen and (min-width: $bp-medium) {
                    grid-row: 3;
                    grid-column: 1 / -1;
                }
                @media only screen and (min-width: $bp-large) {
                    grid-row: 4;
                }
            }
        }
    }
}
