.contacts {
    grid-column: center-start / center-end;

    display: grid;
    grid-template-rows: repeat(4, min-content);

    &_content {
        // display: grid;
        // grid-template-rows: repeat(2, min-content);
        // row-gap: 4rem;

        font-size: 1.4rem;
        width: auto;

        &.animated {
            animation: apperance 1.5s ease-in;
            animation-fill-mode: backwards;
        }

        // @media only screen and (min-width: $bp-largest) {
        //     grid-template-rows: 1fr;
        //     grid-template-columns: 1fr 1fr;
        //     column-gap: 3rem;
        // }
    }
    .details {
        background-color: $color-white;
        grid-row: 1;

        display: grid;
        grid-template-rows: repeat(2, min-content);
        row-gap: 2rem;
        padding: 2.5rem;
        border-radius: 3px;
        box-shadow: $container-box-shadow;
        justify-items: center;

        @media only screen and (min-width: $bp-medium-l) {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
        }

        .text {
            display: grid;
            grid-template-rows: repeat(3, min-content);
            row-gap: 2rem;

            @media only screen and (min-width: $bp-medium) {
                // grid-template-rows: repeat(3, min-content);
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                align-self: center;
                justify-self: center;
                column-gap: 5rem;
                padding-left: 4rem;
            }
            @media only screen and (min-width: $bp-x-large) {
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 1fr;
            }

            .contact-info {
                display: flex;

                align-items: center;

                &__icon {
                    font-size: 1.8rem;
                    border-radius: 50%;
                    width: 4rem;
                    height: 4rem;
                    padding: 0.5rem;
                    background-color: $color-tertiary;
                    display: flex;
                    cursor: pointer;
                    transition: all 0.3s;
                    border: 2px solid $color-tertiary;
                    transform: translate(0%, -10%);
                    &:hover {
                        background-color: rgba($color-tertiary, 0.3);
                    }
                }
                // &__icon {
                //     transform: translate(25%, 25%);
                //     width: 4rem;
                //     height: 4rem;
                //     padding: 0.5rem;
                // }
                &__texts {
                    padding-left: 1.4rem;
                    font-size: 1.4rem;
                }
                &__title {
                    font-weight: 600;
                }
                a {
                    color: inherit;
                    text-decoration-color: $color-tertiary;
                }
            }
        }
    }
    &__map-box {
        height: 29rem;
        .map {
            width: 100%;
            height: 100%;
        }
    }
    &__form {
        background-color: $color-white;
        border-radius: 3px;
        box-shadow: $container-box-shadow;
        grid-row: 2;
        height: auto;
        width: 100%;

        @media only screen and (min-width: $bp-medium-l) {
            grid-row: 1;
            grid-column: 2;
        }
    }
}

// .contacts {
//     grid-column: center-start / center-end;

//     display: grid;
//     grid-template-rows: repeat(4, min-content);

//     &__content {
//         display: grid;
//         grid-template-rows: repeat(2, min-content);
//         row-gap: 4rem;

//         font-size: 1.4rem;
//         width:max-content;

//         &.animated {
//             animation: apperance 1.5s ease-in;
//             animation-fill-mode: backwards;
//         }

//         // @media only screen and (min-width: $bp-medium-l) {
//         //     grid-template-rows: 1fr;
//         //     grid-template-columns: 1fr 1fr;
//         //     column-gap: 3rem;
//         // }
//     }
//     &__details {
//         background-color: $color-white;
//         grid-row: 1;

//         display: grid;
//         grid-template-rows: repeat(2, min-content);
//         row-gap: 2rem;
//         padding: 2.5rem;
//         border-radius: 3px;
//         box-shadow: $container-box-shadow;
//         justify-items: center;

//         @media only screen and (min-width: $bp-medium-l) {
//             grid-template-rows: 1fr;
//             grid-template-columns: 1fr;
//         }

//         .text {
//             display: grid;
//             grid-template-rows: repeat(3, min-content);
//             row-gap: 2rem;

//             @media only screen and (min-width: $bp-medium) {
//                 // grid-template-rows: repeat(3, min-content);
//                 display: grid;
//                 grid-template-columns: repeat(2, 1fr);
//                 grid-template-rows: repeat(2, 1fr);
//                 align-self: center;
//                 justify-self: center;
//                 column-gap: 5rem;
//                 padding-left: 4rem;
//             }
//             @media only screen and (min-width: $bp-medium-l) {
//                 grid-template-columns: repeat(4, 1fr);
//                 grid-template-rows: 1fr;
//             }

//             .contact-info {
//                 display: flex;

//                 align-items: center;

//                 &__icon {
//                     font-size: 1.8rem;
//                     border-radius: 50%;
//                     width: 4rem;
//                     height: 4rem;
//                     padding: 0.5rem;
//                     background-color: $color-tertiary;
//                     display: flex;
//                     cursor: pointer;
//                     transition: all 0.3s;
//                     border: 2px solid $color-tertiary;
//                     transform: translate(0%, -10%);
//                     &:hover {
//                         background-color: rgba($color-tertiary, 0.3);
//                     }
//                 }
//                 // &__icon {
//                 //     transform: translate(25%, 25%);
//                 //     width: 4rem;
//                 //     height: 4rem;
//                 //     padding: 0.5rem;
//                 // }
//                 &__texts {
//                     padding-left: 1.4rem;
//                 }
//                 &__title {
//                     font-weight: 600;
//                 }
//                 a {
//                     color: inherit;
//                     text-decoration-color: $color-tertiary;
//                 }
//             }
//         }
//     }
//     &__map-box {
//         height: 29rem;
//         .map {
//             width: 100%;
//             height: 100%;
//         }
//         @media only screen and (min-width: $bp-medium-l) {
//             height: 40rem;
//         }
//     }
//     &__form {
//         background-color: $color-white;
//         border-radius: 3px;
//         box-shadow: $container-box-shadow;
//         grid-row: 2;
//         height: auto;
//         width: 100%;

//         @media only screen and (min-width: $bp-medium-l) {
//             grid-row: 1;
//             grid-column: 2;
//         }
//     }
// }
