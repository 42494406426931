*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px

    @media only screen and (max-width: $bp-medium-l) {
        // width < 965px;
        font-size: 50%; //1rem - 8px, 8/16 = 50%
    }

    @media only screen and (max-width: $bp-largest) {
        // width < 1200 px
        font-size: 62.5%;
    }

    @media only screen and (min-width: $bp-x-large) {
        font-size: 70%;
    }
}

body {
    font-family: $font-primary;
    color: $color-grey-dark-2;
    font-weight: 300;
    line-height: 1.6;
}

.container {
    display: grid;
    grid-template-rows: 100vh repeat(5, min-content);
    // grid-template-columns:
    //     [sidebar-start] 280px [sidebar-end full-start] minmax(6rem, 1fr)
    //     [center-start] repeat(
    //         8,
    //         [col-start] minmax(min-content, 14rem) [col-end]
    //     )
    //     [center-end] minmax(6rem, 1fr) [full-end];
    // grid-template-columns:
    //     [full-start] minmax(3rem, 1fr)
    //     [center-start] repeat(
    //         8,
    //         [col-start] minmax(min-content, 14rem) [col-end]
    //     )
    //     [center-end] minmax(3rem, 1fr) [full-end];

    // @media screen and (min-width: $bp-largest) {
    //     grid-template-columns:
    //         [sidebar-start] 280px [sidebar-end full-start] minmax(6rem, 1fr)
    //         [center-start] repeat(
    //             8,
    //             [col-start] minmax(min-content, 14rem) [col-end]
    //         )
    //         [center-end] minmax(6rem, 1fr) [full-end];
    // }
    grid-template-columns: [full-start] [full-end];

    .background {
        grid-column: full-start / full-end;
        position: relative;

        display: grid;
        // grid-template-columns:

        // @media only screen and (max-width: $bp-largest) {
            grid-template-columns:
                minmax(5rem, 1fr)
                [center-start] repeat(
                    8,
                    [col-start] minmax(min-content, 14rem) [col-end]
                )
                [center-end] minmax(5rem, 1fr);
            // }

        @media screen and (min-width: $bp-largest) {
            grid-template-columns:
                [sidebar-start] 280px [sidebar-end] minmax(5rem, 1fr)
                [center-start] repeat(
                    8,
                    [col-start] minmax(10rem, max-content) [col-end]
                )
                [center-end] minmax(5rem, 1fr);
        }
        // @media screen and (min-width: $bp-extra) {
        //     grid-template-columns:
        //         [sidebar-start] 280px [sidebar-end] 6rem
        //         [center-start] repeat(
        //             8,
        //             [col-start] minmax(max-content, 14rem) [col-end]
        //         )
        //         [center-end] 6rem;
        // }

        // @media screen and (min-width: $bp-extra) {
        //     grid-template-columns:
        //         [sidebar-start] 280px [sidebar-end] 6rem
        //         [center-start] repeat(
        //             8,
        //             [col-start] minmax(min-content, 14rem) [col-end]
        //         )
        //         [center-end] 6rem;
        // }
        &.white {
            background-color: $color-white;
        }
        &.grey {
            background-color: $color-grey-light-1;
        }
    }
}
