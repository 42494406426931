.home {
  grid-column: full-start / full-end;
  height: 101vh;

  // background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  //   url(../../assets/images/profile/profile-13.png);
  // background-size: cover;
  // background-position: center;

  //background-attachment: fixed;

  &__container {
    display: grid;
    position: relative;
  }

  &__dark-layer {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__image-container {
    position: absolute;
    display: flex;
    width: 100%;
    z-index: 1;
    .image {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  display: grid;
  //grid-template-rows: 101vh;

  @media only screen and (min-width: $bp-small) {
    //grid-template-columns: 1fr 1fr;
  }

  &__text {
    position: absolute;
    z-index: 3;
    color: $color-grey-light-1;
    align-self: center;
    justify-self: center;
    font-size: 3rem;
    font-weight: 400;
    @media only screen and (min-width: $bp-medium-l) {
      //justify-self: flex-end;
      left: 35rem;
    }
  }
  &__profesion {
    display: flex;
    justify-content: space-around;
    @for $i from 1 through 16 {
      .sp-#{$i} {
        .underline {
          &::after {
            width: 2.6rem;
          }
        }
        &.animated {
          animation: apperance 0.1s;
          animation-fill-mode: backwards;
          animation-delay: #{0.5s + 0.1s * ($i - 1)};
        }
      }
    }
  }
  &__word {
    display: flex;
  }
}
