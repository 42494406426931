.form {
    display: grid;
    grid-template-rows: repeat(3, min-content);
    row-gap: 2rem;
    padding: 2.5rem;
    &__element {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        row-gap: 1rem;
        input,
        textarea {
            background-color: rgba($color-grey-light-1, 0.3);
            border: 1px solid $color-grey-light-1;
            padding-left: 1.4rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 1.4rem;
            color: $color-grey-dark-1;
        }
        input:focus,
        textarea:focus {
            outline: none;
        }
        label {
            font-weight: 600;
        }
    }
    &__button {
        .btn {
            font-weight: 600;
        }
    }
}
