$color-primary: #c69963;
$color-secondary: #080f17;
$color-tertiary: aquamarine;

$color-grey-light-1: #f0f0f1;
$color-grey-light-2: rgb(147, 146, 146);
$color-grey-light-3: rgb(181, 179, 179);

$color-grey-dark-1: #555454;
$color-grey-dark-2: #393838;

$color-white: white;
$color-black: black;

$container-box-shadow: 0 0 15px 0px rgba($color-black, 0.1);

// FONT VARIABLES
$font-primary: 'Nunito', sans-serif;
$font-display: 'Josefin Sans', sans-serif;

// RESPONSIVE BREAKPOINTS
$bp-extra: 90rem; //1520px
$bp-x-large: 88rem; //1420px
$bp-largest: 75em; // 1200px
$bp-large: 64em; // 1024px
$bp-medium-l: 60.3125em; // 965px;
$bp-medium: 48em; // 768px;
$bp-small: 37.5em; // 600px;
