@mixin respond($breakpoint) {
    @if $breakpoint == phone-sm {
        @media (max-width: 28.5em) {
            //448px
            @content;
        }
    }
    @if $breakpoint == phone {
        @media (max-width: $bp-small) {
            //600px
            @content;
        }
    }
    @if $breakpoint == tablet {
        @media (max-width: $bp-medium) {
            //800px
            @content;
        }
    }
    @if $breakpoint == tab-land {
        @media (max-width: $bp-large) {
            //1000px
            @content;
        }
    }

    @if $breakpoint == desktop {
        @media (min-width: $bp-largest) {
            //1200px
            @content;
        }
    }
}
@mixin caption-positioning {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.5s;
}
@mixin position-absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin image-fit {
    object-fit: cover;
    object-position: center;
}
