.heading-1 {
    font-size: 4rem;
    text-transform: uppercase;
}
.heading-2 {
    font-size: 3rem;
    grid-column: 1 / -1;
}
.heading-3 {
    font-size: 2.6rem;
}
.heading-4 {
    font-size: 2.2rem;
    @media only screen and (max-width: $bp-medium) {
        font-size: 2rem;
    }
}
.underline {
    &::after {
        width: 14rem;
        height: 4px;
    }
}
.description {
    grid-column: 1 / -1;
    padding: 3rem 0;
    font-size: 1.6rem;
    text-align: justify;
    @media only screen and (min-width: $bp-medium-l) {
        padding: 6rem 0;
    }
    @media only screen and (min-width: $bp-large) {
        padding: 6rem 0;
    }
    @media only screen and (min-width: $bp-largest) {
        padding: 6rem 0;
    }
}

.heading-5 {
    font-size: 2rem;
}
