.underline {
    position: relative;
    &::after {
        // width: 2.2rem;
        height: 2px;
        background-color: $color-tertiary;
        display: inline-block;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s ease;
    }
}
@-moz-document url-prefix() {
    .underline {
        &::after {
            top: 2rem;
        }
    }
}
