.portfolio {
    grid-column: center-start / center-end;
    background-color: $color-white;

    display: grid;
    grid-template-rows: repeat(2, min-content) max-content;

    &__content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        gap: 3rem;

        &.animated {
            animation-name: moveInBottom;
            animation-duration: 1.5s;
            animation-timing-function: ease-out;
            animation-fill-mode: backwards;
        }

        @media only screen and (min-width: $bp-small) {
            gap: 4rem;
        }

        @media only screen and (min-width: $bp-medium) {
            gap: 5rem;
        }

        &--item {
            width: 100%;
            height: 100%;
            border-radius: 7px;
            overflow: hidden;
            position: relative;
            box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.1);
            .heading-5 {
                padding-bottom: 2rem;
            }
            &:hover .portfolio__content--caption {
                opacity: 1;
            }
            &:hover .portfolio__content--image {
                transform: scale(1.25);
            }
        }
        &--image {
            width: 100%;
            height: 100%;
            @include image-fit;
            transition: all 0.4s;
        }
        &--caption {
            background: rgba($color-white, 0.8);
            @include caption-positioning;
            justify-content: center;
            transition: all 0.4s;
            .heading-5 {
                font-size: 1.6rem;
            }
        }
    }
}
