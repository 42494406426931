@keyframes moveInLeft {
    /*using usualy 2 properties - opacity and transform*/
    0% {
        opacity: 0;
        transform: translateX(-5rem); /*animation in X direction*/
    } /*start*/

    80% {
        transform: translate(0.5rem);
    } /*middle*/

    100% {
        opacity: 1;
        transform: translate(0); /*back to initial state*/
    } /* finish */
}

@keyframes moveInRight {
    /*using usualy 2 properties - opacity and transform*/
    0% {
        opacity: 0;
        transform: translateX(5rem); /*animation in X direction*/
    } /*start*/

    80% {
        transform: translate(-0.5rem);
    } /*middle*/

    100% {
        opacity: 1;
        transform: translate(0); /*back to initial state*/
    } /* finish */
}

@keyframes moveInBottom {
    /*using usualy 2 properties - opacity and transform*/
    0% {
        opacity: 0;
        transform: translateY(
            5rem
        ); /*animation in Y direction, positive moving down*/
    } /*start*/

    100% {
        opacity: 1;
        transform: translate(0);
    } /* finish */
}

@keyframes apperance {
    /*using usualy 2 properties - opacity and transform*/
    0% {
        opacity: 0;
        // transform: translateY(
        //     15rem
        // ); /*animation in Y direction, positive moving down*/
    } /*start*/

    100% {
        opacity: 1;
        // transform: translate(0);
    } /* finish */
}
