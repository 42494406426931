.image-slider {
    max-height: 100%;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 1rem 2rem 7rem 2rem;
    @media only screen and (min-width: $bp-medium) {
        width: 70%;
        height: 100%;
        margin: 0 auto;
    }
    @media only screen and (min-width: $bp-large) {
        width: 100%;
    }
    &--title {
        font-size: 1.4rem;
        padding-bottom: 1rem;
        text-align: center;
    }
    &__arrow {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 2.4rem;
        color: $color-tertiary;
        z-index: 30;
        cursor: pointer;
        &.left {
            left: 4rem;
        }
        &.right {
            right: 4rem;
        }
    }
    &__slide {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        border-radius: 7px;
    }
    &__dots {
        display: flex;
        width: 100%;
        justify-content: center;
        align-self: center;
        font-size: 1.4rem;
        padding-top: 1rem;
        cursor: pointer;
        transition: all 0.3s;
        .dot {
            margin-right: 1rem;
            color: $color-grey-dark-2;
            &:hover,
            &.active {
                color: $color-tertiary;
            }
        }
    }
}
