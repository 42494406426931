.resume {
    grid-column: center-start / center-end;
    &__content {
        font-size: 1.6rem;
        display: grid;
        @media only screen and (min-width: $bp-medium-l) {
            grid-template-rows: repeat(2, min-content);
            grid-template-columns: 1fr 1fr;
            column-gap: 3rem;
        }
    }
    &__section {
        .heading-3 {
            padding-bottom: 2.5rem;
        }
        @media only screen and (min-width: $bp-medium-l) {
            &--1 {
                grid-column: 1;
                grid-row: 1;
            }
            &--2 {
                grid-column: 1;
                grid-row: 2;
            }
            &--3 {
                grid-column: 2;
                grid-row: 1;
            }
            &--4 {
                grid-column: 2;
                grid-row: 2;
            }
        }
    }
    &__text {
        display: grid;
        grid-template-columns: 1px 1fr;
        padding-bottom: 3rem;
        &.animated {
            animation-name: moveInBottom;
            animation-duration: 1.5s;
            animation-timing-function: ease-out;
            animation-fill-mode: backwards;
        }
        &--line-left {
            grid-column: 1;
            margin-top: 2rem;
            border: 1px solid $color-tertiary;
        }
        &--column-right {
            grid-column: 2;
        }

        &--title {
            display: flex;
            padding-bottom: 1.6rem;
            span {
                width: 1.5rem;
                height: 1.5rem;
                background-color: $color-tertiary;
                border-radius: 50%;
                transform: translate(-50%, 50%);
            }
            .heading-4 {
                margin-left: 1.5rem;
            }
        }
        &--description {
            margin-left: 3rem;
            padding-bottom: 1.6rem;
            font-style: italic;
        }
        &--date {
            width: max-content;
            padding: 0.05rem 0.75rem 0.04rem;
            //background-color: rgba($color-tertiary, 0.3);
            background-color: $color-white;
            border-radius: 3px;
            border-bottom: 1.5px solid $color-tertiary;
            margin-left: 3rem;
            margin-bottom: 1.6rem;
            font-weight: 400;
        }
        &--list {
            margin-left: 3rem;
        }
    }
}
