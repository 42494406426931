.btn {
  border: none;
  background-color: $color-tertiary;
  padding: 0.75rem 1.5rem;
  border-radius: 2.5rem;
  cursor: pointer;
  transition: all 0.3s;
  color: $color-grey-dark-2;
  &:hover {
    transform: translateY(-3px);
    box-shadow: $container-box-shadow;
  }
  &.caption {
  }
  &__language {
    padding: 0.5rem 1rem;
    background-color: $color-tertiary;
    border: none;
    border-radius: 3px;
    color: $color-secondary;
    cursor: pointer;
  }
}
